import React, { useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { AppContext, App } from '../components/app';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';
import Copy2Cliboard from '../util/copy2clipboard';

const ContactPage = ({ location }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    useEffect(() => {
        if (!state || !state.affiliateId) {
            navigate('/login');
        }
    }, []);

    const copyToClipboard = e => {
        Copy2Cliboard.select_all_and_copy(e.target);
    };

    if (!state || !state.affiliateId) {
        return <div></div>;
    }

    return (
        <Layout>
            <Menu location={location} />

            <h1 className="huge nohyphens">Banners</h1>
            <p className="padding-bottom-0">
                Brand new Watch4beauty banners are ready for your websites! You
                can download each package separately, square, vertical or
                horizontal. If you need a different size, do not hesitate to
                contact us.
            </p>
            <div className="spacer line spacer-80">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Square banners</h1>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/banners-001.jpg')}
                width="100%"
                height="auto"
                alt="Square banners"
            />
            <p className="link link-button">
                <a
                    href="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-square.zip"
                    title="Download .ZIP"
                >
                    Download .ZIP
                </a>
            </p>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Vertical banners</h1>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/banners-002.jpg')}
                width="100%"
                height="auto"
                alt="Vertical banners"
            />
            <p className="link link-button">
                <a
                    href="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-vertical.zip"
                    title="Download .ZIP"
                >
                    Download .ZIP
                </a>
            </p>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Horizontal banners</h1>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/banners-003.jpg')}
                width="100%"
                height="auto"
                alt="Horizontal banners"
            />
            <p className="link link-button">
                <a
                    href="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-horizontal.zip"
                    title="Download .ZIP"
                >
                    Download .ZIP
                </a>
            </p>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Discount banners</h1>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/banners-005.jpg')}
                width="100%"
                height="auto"
                alt="Discount banners"
            />
            <p className="link link-button">
                <a
                    href="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-discount.zip"
                    title="Download .ZIP"
                >
                    Download .ZIP
                </a>
            </p>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Logo buttons</h1>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/banners-004.jpg')}
                width="100%"
                height="auto"
                alt="Buttons"
            />
            <p className="link link-button">
                <a
                    href="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-buttons.zip"
                    title="Download .ZIP"
                >
                    Download .ZIP
                </a>
            </p>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens">Video banners</h1>
            <p className="padding-bottom-0">
                Free hosted video banners. Just copy and place the code into
                your website. We will do the rest. You can adjust the size
                480x320 pixels in the code, 3:2 is the aspect ratio. Banners
                are&nbsp;optimized for all devices!
            </p>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        <div className="noborder">
                            <div className="panel">
                                <a
                                    href={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&amp;PA=${state.affiliateId}`}
                                    title="Join Watch4beauty"
                                >
                                    <video
                                        preload="auto"
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-casting-001.jpg"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <source
                                            src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-casting-001.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </a>
                            </div>
                            <div className="spacer spacer-16">&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="code001"
                                    className="has-value"
                                    rows="11"
                                    onFocus={copyToClipboard}
                                    value={`<a href="http://fhg.bcash4you.com/w4b/potd/latest-issue.html?${state.affiliateId}" title="Watch4beauty"><img src="http://www.watch4beauty.com/latest-issue-400.jpg" border="0" alt="Watch4beauty" /></a>`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="code001"
                                >
                                    Video banner 001 HTML code
                                </label>
                            </div>
                        </div>
                        <div className="spacer spacer-32">&nbsp;</div>

                        <div className="noborder">
                            <div className="panel">
                                <a
                                    href={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&amp;PA=${state.affiliateId}`}
                                    title="Join Watch4beauty"
                                >
                                    <video
                                        preload="auto"
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-masturbation-001.jpg"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <source
                                            src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-masturbation-001.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </a>
                            </div>
                            <div className="spacer spacer-16">&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="code002"
                                    className="has-value"
                                    rows="11"
                                    onFocus={copyToClipboard}
                                    value={`<div><a href="http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}" title="Join Watch4beauty"><video preload="auto" autoplay loop muted playsinline poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-masturbation-001.jpg" style="width:480px;height:320px;background-size:cover;" ><source src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-masturbation-001.mp4" type="video/mp4"/></video></a></div>`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="code002"
                                >
                                    Video banner 002 HTML code
                                </label>
                            </div>
                        </div>
                        <div className="spacer spacer-32">&nbsp;</div>

                        <div className="noborder">
                            <div className="panel">
                                <a
                                    href={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&amp;PA=${state.affiliateId}`}
                                    title="Join Watch4beauty"
                                >
                                    <video
                                        preload="auto"
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-girls-001.jpg"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <source
                                            src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-girls-001.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </a>
                            </div>
                            <div className="spacer spacer-16">&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="code003"
                                    className="has-value"
                                    rows="11"
                                    onFocus={copyToClipboard}
                                    value={`<div><a href="http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}" title="Join Watch4beauty"><video preload="auto" autoplay loop muted playsinline poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-girls-001.jpg" style="width:480px;height:320px;background-size:cover;" ><source src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-girls-001.mp4" type="video/mp4"/></video></a></div>`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="code003"
                                >
                                    Video banner 003 HTML code
                                </label>
                            </div>
                        </div>
                        <div className="spacer spacer-32">&nbsp;</div>

                        <div className="noborder">
                            <div className="panel">
                                <a
                                    href={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&amp;PA=${state.affiliateId}`}
                                    title="Join Watch4beauty"
                                >
                                    <video
                                        preload="auto"
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-peeing-001.jpg"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <source
                                            src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-peeing-001.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </a>
                            </div>
                            <div className="spacer spacer-16">&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="code004"
                                    className="has-value"
                                    rows="11"
                                    onFocus={copyToClipboard}
                                    value={`<div><a href="http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}" title="Join Watch4beauty"><video preload="auto" autoplay loop muted playsinline poster="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-peeing-001.jpg" style="width:480px;height:320px;background-size:cover;" ><source src="https://e2g5m9i7.ssl.hwcdn.net/images/banners/w4b/latest/w4b-peeing-001.mp4" type="video/mp4"/></video></a></div>`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="code004"
                                >
                                    Video banner 004 HTML code
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="spacer spacer-80">&nbsp;</div>

            <Footer />
        </Layout>
    );
};

export default ContactPage;
